import React, { useState, useEffect, useCallback, useMemo, useId } from 'react';
import { useFloating, autoUpdate, flip, shift, size, hide } from '@floating-ui/react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faEllipsisV, faXmark } from '@fortawesome/free-solid-svg-icons';
import { IListItem, SelectDropDown } from './select-dropdown';
import { Menu, MenuItem } from '@ui/menu';
import { Label } from '..';
import cx from 'classnames';
import styles from './select.module.css';

export type Selection<T, M extends boolean> = M extends true ? T[] : T | undefined;
export type SelectFn<T, M extends boolean> = (selected: Selection<T, M>) => void;

export interface ISelectPropsBase<T, M extends boolean = false> {
  id?: string;
  label?: string;
  placeholder?: string;

  multiselect?: M;

  tabIndex?: number;
  disabled?: boolean;
  hideSearch?: boolean;
  clearButton?: boolean;
  canSelect?(item: T, index: number): boolean;

  menuItems?: MenuItem[];

  className?: string;
  classNameWrapper?: string;
  classNameContainer?: string;
  classNameInput?: string;
  classNameDropDown?: string;
  classNameListItem?: string;
  stretch?: boolean;

  cyId?: string;

  onBlur?(): void;
}

export interface ISelectProps<T, M extends boolean = false> extends ISelectPropsBase<T, M> {
  items: T[];
  itemName?: keyof T | ((item: T, index: number) => string);

  selected?: number[] | ((item: IListItem<T>) => boolean);

  onSelectionChanged?: SelectFn<IListItem<T>, M>;
  onSelectionStart?: SelectFn<IListItem<T>, M>;
  onSelectionFinish?: SelectFn<IListItem<T>, M>;
}

function SelectComponent<T, M extends boolean = false>(
  props: ISelectProps<T, M>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const {
    id,
    label,
    placeholder,
    items,
    itemName,
    selected: isSelected,
    multiselect,
    tabIndex,
    hideSearch,
    clearButton,
    disabled,
    menuItems,
    className = '',
    classNameWrapper = '',
    classNameContainer = '',
    classNameInput = '',
    classNameDropDown,
    classNameListItem,
    cyId,
    stretch,
    onSelectionChanged,
    onSelectionStart,
    onSelectionFinish,
    canSelect,
    onBlur
  } = props;
  const [active, setActive] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);

  const [text, setText] = useState('');
  const [listItems, setListItems] = useState<IListItem<T>[]>([]);
  const [selected, setSelected] = useState(new Set<number>());

  const [showMenu, setShowMenu] = useState(false);

  const generated_id = useId();
  const selectId = id ?? generated_id;

  const { x, y, reference, floating, middlewareData, refs } = useFloating<HTMLElement>({
    placement: 'bottom-start',
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
    middleware: [
      flip(),
      shift(),
      size({
        apply: options => {
          Object.assign(options.elements.floating.style, {
            maxHeight: `${options.availableHeight}px`,
            minWidth: `${options.elements.reference.getBoundingClientRect().width}px`
          });
        }
      }),
      hide()
    ]
  });

  const selectRef = refs.reference;
  const placeholderVisible = !!placeholder && selected.size == 0;

  useEffect(() => {
    // Prepare list items collection
    const listItems: IListItem<T>[] = items.map((item, index) => {
      let itemText = '';
      if (itemName) {
        if (typeof itemName === 'function') {
          itemText = itemName(item, index);
        } else {
          itemText = String(item[itemName]);
        }
      } else {
        itemText = String(item);
      }

      return { value: item, index, text: itemText, canSelect: canSelect?.(item, index) ?? true };
    });

    setListItems(listItems);

    // Store indexes of selected items
    if (isSelected) {
      const selectedIndexes =
        typeof isSelected === 'function'
          ? listItems.filter(item => isSelected(item)).map(item => item.index)
          : isSelected;

      var set = new Set<number>(selectedIndexes);
      setSelected(new Set(listItems.filter(x => set.has(x.index)).map(x => x.index)));
    }
  }, [items, itemName, isSelected, canSelect]);

  useEffect(() => {
    // Compose text to be displayed
    if (!selected.size) {
      setText(placeholderVisible ? placeholder : '');
    } else {
      const text = [...listItems.values()]
        .filter(item => selected.has(item.index))
        .sort((left, right) => left.index - right.index)
        .map(item => item.text)
        .join(', ');
      setText(text);
    }
  }, [placeholderVisible, selected, listItems]);

  const changeDropDownState = useCallback(
    (state: 'show' | 'hide' | 'toggle') => {
      const selectedItems: IListItem<T>[] = listItems.filter(item => selected.has(item.index));
      const selectedItem: IListItem<T> | undefined = selectedItems.length
        ? selectedItems[0]
        : undefined;
      const selection = (multiselect ? selectedItems : selectedItem) as Selection<IListItem<T>, M>;

      switch (state) {
        case 'show':
          setShowDropDown(visible => {
            if (!visible) onSelectionStart?.(selection);
            return true;
          });
          break;
        case 'hide':
          setShowDropDown(visible => {
            if (visible) onSelectionFinish?.(selection);
            return false;
          });
          break;
        case 'toggle':
          setShowDropDown(visible => {
            if (!visible) {
              onSelectionStart?.(selection);
            } else {
              onSelectionFinish?.(selection);
            }
            return !visible;
          });
      }
    },
    [listItems, selected, multiselect]
  );

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (disabled) return;
      if (e.defaultPrevented) return;
      switch (e.nativeEvent.code) {
        case 'ArrowDown':
        case 'Enter':
          e.preventDefault();
          changeDropDownState('show');
          break;

        case 'Escape':
          if (showDropDown) {
            e.preventDefault();
            changeDropDownState('hide');
            selectRef.current?.focus();
          }
          break;
      }
    },
    [showDropDown, changeDropDownState]
  );

  const onFocus = useMemo(() => {
    let subscribed = false;

    const onFocus = (ev: React.FocusEvent) => {
      if (disabled || subscribed) return;

      setActive(true);
      const rootElement = ev.currentTarget as Node;

      const onFocusOut = (e: FocusEvent) => {
        deactivateIfOutside(e.relatedTarget as Node);
      };

      const onPointerDown = (e: PointerEvent) => {
        deactivateIfOutside(e.target as Node);
      };

      const deactivateIfOutside = (target: Node) => {
        const inside = !!target && rootElement.contains(target as Node);
        if (!inside) {
          setActive(false);
          changeDropDownState('hide');
          window.removeEventListener('focusout', onFocusOut);
          window.removeEventListener('pointerdown', onPointerDown);
          subscribed = false;
        }
      };

      window.addEventListener('focusout', onFocusOut);
      window.addEventListener('pointerdown', onPointerDown);
      subscribed = true;
    };

    return onFocus;
  }, [disabled]);

  const select = (
    <div
      className={cx(
        styles.root,
        {
          [styles.active]: active || showDropDown,
          [styles.disabled]: disabled,
          [styles.stretch]: !!label || stretch
        },
        className
      )}
      ref={r => {
        reference(r);

        if (ref) {
          if (typeof ref === 'function') ref(r);
          else ref.current = r;
        }
      }}
      tabIndex={disabled ? undefined : tabIndex ?? 0}
      id={selectId}
      cy-id={cyId}
      onFocus={onFocus}
      onClick={e => {
        if (disabled || e.defaultPrevented) return;
        changeDropDownState('toggle');
      }}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
    >
      <div className={cx(styles.wrapper, classNameWrapper)}>
        <div
          className={cx(
            styles.container,
            {
              [styles.active]: active,
              [styles.disabled]: disabled
            },
            classNameContainer
          )}
        >
          <div className={cx(styles.inputWrapper, classNameInput)}>
            <input
              className={cx(styles.input, {
                [styles.placeholder]: placeholderVisible,
                [styles.disabled]: disabled
              })}
              tabIndex={-1}
              value={text}
              type="text"
              role="listbox"
              readOnly={true}
              disabled={disabled}
              cy-id={cyId ? `${cyId}-input` : undefined}
            />

            {!disabled && clearButton ? (
              <span
                className={cx(styles.glyph, styles.clearButton, {
                  [styles.hidden]: !selected.size
                })}
                onClick={e => {
                  e.preventDefault();
                  setSelected(
                    new Set<number>(
                      listItems
                        // keep items that are non-selectable but "selected" by default
                        .filter(item => !item.canSelect && selected.has(item.index))
                        .map(item => item.index)
                    )
                  );

                  const selection = (multiselect ? [] : undefined) as Selection<IListItem<T>, M>;
                  onSelectionChanged?.(selection);
                  if (!showDropDown) onSelectionFinish?.(selection);
                }}
                cy-id={cyId ? `${cyId}-clear-btn` : undefined}
              >
                <FontAwesomeIcon icon={faXmark} />
              </span>
            ) : null}

            {menuItems?.length ? (
              <span
                className={cx(styles.glyph, styles.menuButton, {
                  [styles.hidden]: disabled
                })}
                onClick={e => {
                  e.preventDefault();
                  setShowMenu(true);
                }}
                cy-id={cyId ? `${cyId}-menu-btn` : undefined}
              >
                <FontAwesomeIcon icon={faEllipsisV} />
                {showMenu ? (
                  <Menu
                    items={menuItems.map(item => {
                      return {
                        ...item,
                        onClick: () => {
                          item.onClick?.();
                          selectRef.current?.focus();
                        }
                      };
                    })}
                    onDismiss={() => setShowMenu(false)}
                  />
                ) : null}
              </span>
            ) : null}
          </div>

          <span className={styles.glyph} cy-id={cyId ? `${cyId}-dropdown-btn` : undefined}>
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        </div>

        {!disabled && showDropDown && (
          <SelectDropDown
            ref={floating}
            style={{
              left: `${x ?? 0}px`,
              top: `${y ?? 0}px`,
              visibility: middlewareData.hide?.referenceHidden ? 'hidden' : 'visible'
            }}
            items={listItems}
            selectedItems={selected}
            multiselect={multiselect}
            hideSearch={hideSearch}
            className={classNameDropDown}
            classNameItem={classNameListItem}
            cyId={cyId}
            onSelectionChange={data => {
              const updated = new Set<number>(multiselect ? selected : undefined);
              if (multiselect) {
                data.forEach(item => {
                  item.selected ? updated.add(item.index) : updated.delete(item.index);
                });
              } else if (data.length > 0) {
                updated.add(data[0].index);
              }

              setSelected(updated);
              if (!multiselect) {
                changeDropDownState('hide');
                selectRef.current?.focus();
              }

              const selectedItems = listItems.filter(item => updated?.has(item.index));
              const selectedItem = selectedItems.length ? selectedItems[0] : undefined;
              const selection = (multiselect ? selectedItems : selectedItem) as Selection<
                IListItem<T>,
                M
              >;
              onSelectionChanged?.(selection);
            }}
          />
        )}
      </div>
    </div>
  );

  return label ? (
    <Label value={label} htmlFor={selectId} bold>
      {select}
    </Label>
  ) : (
    select
  );
}

// Redecalare forwardRef
declare module 'react' {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

export const Select = React.forwardRef(SelectComponent);
