import React, { forwardRef, ForwardedRef, useId, InputHTMLAttributes } from 'react';
import { Label } from '@ui/label';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import cx from 'classnames';
import styles from './check-input.module.css';

export interface ICheckInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  label?: string;
}

interface ICheckInputPropsEx extends ICheckInputProps {
  type: 'checkbox' | 'radio';
}

const CheckInput = forwardRef((props: ICheckInputPropsEx, ref?: ForwardedRef<HTMLInputElement>) => {
  const { type, label, className, id, ...rest } = props;

  const generated_id = useId();
  const check_id = props.id ?? generated_id;

  return (
    <div className={cx(styles.root, className ?? '')}>
      <input
        type={type}
        ref={ref}
        className={cx(styles.input, {
          [styles.checkbox]: type === 'checkbox',
          [styles.radio]: type === 'radio'
        })}
        id={check_id}
        {...rest}
      />
      {type === 'checkbox' ? <FontAwesomeIcon icon={faCheck} className={styles.checkMark} /> : null}
      {label ? <Label htmlFor={check_id} className={styles.label} value={label} /> : null}
    </div>
  );
});

export const Checkbox = forwardRef(
  (props: ICheckInputProps, ref?: ForwardedRef<HTMLInputElement>) => (
    <CheckInput type="checkbox" ref={ref} {...props} />
  )
);

export const RadioButton = forwardRef(
  (props: ICheckInputProps, ref?: ForwardedRef<HTMLInputElement>) => (
    <CheckInput type="radio" ref={ref} {...props} />
  )
);
