import React, { useState, useRef, useEffect, useDeferredValue, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import textSearch from '../../../utils/text-search';
import { Checkbox } from '../check-input';
import cx from 'classnames';
import styles from './select-dropdown.module.css';

export interface IListItem<T> {
  text: string;
  index: number;
  value: T;
  canSelect: boolean;
}

interface IDropDownProps<T> {
  items: IListItem<T>[];
  style?: React.CSSProperties;
  selectedItems: Set<number>;
  multiselect?: boolean;
  onSelectionChange(selection: { index: number; selected?: boolean }[]): void;
  hideSearch?: boolean;
  className?: string;
  classNameItem?: string;
  cyId?: string;
}

interface IListItemProps<T> {
  item: IListItem<T>;
  multiselect?: boolean;
  isSelected: boolean;
  hasCursor: boolean;
  itemRef?: React.Ref<HTMLDivElement>;
  className?: string;
  cyId?: string;
  onClick(index: number): void;
  onHover(index: number): void;
}

export function SelectDropDownComponent<T>(
  props: IDropDownProps<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const {
    items,
    selectedItems,
    multiselect,
    hideSearch,
    className = '',
    classNameItem,
    cyId,
    onSelectionChange
  } = props;
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const deferredSearch = useDeferredValue(search);
  const [filteredItems, setFilteredItems] = useState<IListItem<T>[]>([...items]);
  const [cursorItemIndex, setCursorItemIndex] = useState<number | null>(null);
  const dropDownRef = useRef<HTMLDivElement>();
  const searchRef = useRef<HTMLInputElement>(null);
  const itemsRef = useRef<(HTMLDivElement | null)[]>([].slice(0, items.length));

  useEffect(() => {
    // Focus search field by default
    if (searchRef.current) searchRef.current.focus();
    else (dropDownRef.current?.firstChild as HTMLElement)?.focus();
  }, [searchRef.current]);

  const ensureVisible = useCallback((index: number) => {
    if (index < 0 || index >= itemsRef.current.length) return;

    const listElement = itemsRef.current[index];
    if (!listElement || !listElement.parentElement) return;

    const parentRect = listElement.parentElement.getBoundingClientRect();
    const rect = listElement.getBoundingClientRect();
    if (rect.bottom > parentRect.bottom) {
      listElement.parentElement.scrollBy(0, rect.bottom - parentRect.bottom);
    } else if (rect.top < parentRect.top) {
      listElement.parentElement.scrollBy(0, rect.top - parentRect.top);
    }
  }, []);

  useEffect(() => {
    // When cursor goes out of visible view - scroll it into the view
    if (cursorItemIndex === null || cursorItemIndex < 0 || cursorItemIndex >= items.length) return;
    ensureVisible(cursorItemIndex);
  }, [cursorItemIndex, items.length]);

  useEffect(() => {
    // resize refs collection
    itemsRef.current = itemsRef.current.slice(0, items.length);
  }, [items.length]);

  useEffect(() => {
    // Apply search
    let filtered: IListItem<T>[] = [];
    if (!deferredSearch) {
      filtered = [...items];
    } else {
      filtered = items.filter(item => {
        const include = textSearch(item.text, deferredSearch);
        if (cursorItemIndex === item.index && !include) {
          setCursorItemIndex(null);
        }
        return include;
      });
    }

    setFilteredItems(filtered);
  }, [deferredSearch, items]);

  useEffect(() => {
    if (!dropDownRef.current) return;
    dropDownRef.current.animate([{ opacity: 0 }, { opacity: 1 }], 100);

    // Make sure that first selected item is visible in the list when animation finishes
    if (cursorItemIndex !== null && cursorItemIndex !== -1) return;
    if (!selectedItems.size) return;

    const firstSelected = items.find(item => selectedItems.has(item.index));
    if (!firstSelected) return;
    ensureVisible(firstSelected.index);
  }, [dropDownRef.current]);

  const canSelectAll = multiselect && filteredItems.length > 1;

  const handleItemClick = (itemIndex: number) => {
    if (canSelectAll && itemIndex === -1) {
      // Select all or select none
      var doSelect = filteredItems.some(item => item.canSelect && !selectedItems.has(item.index));
      const selection = filteredItems
        .filter(item => item.canSelect && selectedItems.has(item.index) !== doSelect)
        .map(item => {
          return { index: item.index, selected: doSelect };
        });
      onSelectionChange(selection);
    } else {
      const canSelect = filteredItems.find(item => item.index === itemIndex)?.canSelect ?? false;
      if (canSelect) {
        // single item selection
        onSelectionChange([{ index: itemIndex, selected: !selectedItems.has(itemIndex) }]);
      }
    }
  };

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      switch (e.nativeEvent.code) {
        case 'ArrowUp':
        case 'ArrowDown':
          {
            e.preventDefault();
            const enabledItems = filteredItems.filter(item => item.canSelect);
            if (enabledItems.length) {
              const navDown = e.nativeEvent.code === 'ArrowDown';
              const minIndex = canSelectAll ? -1 : 0;

              const index = enabledItems.findIndex(item =>
                cursorItemIndex === null
                  ? selectedItems.has(item.index)
                  : item.index === cursorItemIndex
              );

              let nextIndex = -1;
              if (index === -1 && cursorItemIndex === null) {
                const navDownIndex = cursorItemIndex === -1 ? 0 : minIndex;
                nextIndex = navDown ? navDownIndex : enabledItems.length - 1;
              } else {
                nextIndex = navDown
                  ? Math.min(index + 1, enabledItems.length - 1)
                  : Math.max(index - 1, minIndex);
              }

              setCursorItemIndex(nextIndex === -1 ? nextIndex : enabledItems[nextIndex].index);
            }
          }
          break;

        case 'Enter':
          if (filteredItems.length === 1 && filteredItems[0].canSelect) {
            e.preventDefault();
            handleItemClick(filteredItems[0].index);
          } else if (cursorItemIndex !== null) {
            e.preventDefault();
            handleItemClick(cursorItemIndex);
          }
          break;

        case 'Space':
          if (multiselect && cursorItemIndex !== null) {
            e.preventDefault();
            handleItemClick(cursorItemIndex);
          }
          break;
      }
    },
    [filteredItems, multiselect, selectedItems, cursorItemIndex, onSelectionChange, handleItemClick]
  );

  return (
    <div
      className={cx(styles.dropDownRoot, className)}
      style={props.style}
      ref={r => {
        dropDownRef.current = r ?? undefined;

        if (ref) {
          if (typeof ref === 'function') ref(r);
          else ref.current = r;
        }
      }}
      cy-id={cyId ? `${cyId}-dropdown-root` : undefined}
    >
      <div className={styles.dropDown} tabIndex={0} onKeyDown={onKeyDown}>
        {!hideSearch && (
          <div
            className={styles.searchContainer}
            onClick={e => {
              e.preventDefault();
            }}
          >
            <input
              className={styles.search}
              ref={searchRef}
              value={search}
              placeholder={`${t('common:search')}...`}
              cy-id={cyId ? `${cyId}-dropdown-search` : undefined}
              onClick={e => {
                e.preventDefault();
              }}
              onChange={e => setSearch(e.currentTarget.value)}
            />
          </div>
        )}

        {multiselect && filteredItems.length > 1 && (
          <ListItem
            item={{
              index: -1,
              text: t('common:selectAll'),
              value: null as unknown as T,
              canSelect: true
            }}
            multiselect
            isSelected={filteredItems.every(i => !i.canSelect || selectedItems.has(i.index))}
            hasCursor={cursorItemIndex === -1}
            onClick={handleItemClick}
            onHover={setCursorItemIndex}
          />
        )}

        <div
          className={cx(styles.list, 'scrollable')}
          cy-id={cyId ? `${cyId}-dropdown-list` : undefined}
        >
          {filteredItems?.map((item, index) => {
            return (
              <ListItem
                key={item.index}
                item={item}
                itemRef={el => {
                  itemsRef.current[item.index] = el;
                }}
                multiselect={multiselect}
                isSelected={selectedItems.has(item.index)}
                hasCursor={cursorItemIndex === item.index}
                className={classNameItem}
                cyId={cyId ? `${cyId}-dropdown-list-item-${index}` : undefined}
                onClick={handleItemClick}
                onHover={setCursorItemIndex}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

function ListItem<T>(props: IListItemProps<T>) {
  const { item, multiselect, isSelected, hasCursor, itemRef, className, cyId, onClick, onHover } =
    props;

  return (
    <div
      className={cx(
        styles.option,
        {
          [styles.selected]: isSelected,
          [styles.hasCursor]: hasCursor,
          [styles.static]: item.index === -1,
          [styles.disabled]: !item.canSelect
        },
        className
      )}
      cy-id={cyId}
      ref={itemRef}
      onClick={e => {
        e.preventDefault();
        if (!item.canSelect) return;
        onClick(item.index);
      }}
      onPointerMove={e => {
        if (!item.canSelect) return;
        if (hasCursor) return;
        if (!e.currentTarget.parentElement) return;

        const parentRect = e.currentTarget.parentElement.getBoundingClientRect();
        const rect = e.currentTarget.getBoundingClientRect();
        // Don't navigate to item when at least 2/3 of it is not visible
        if (
          (rect.bottom > parentRect.bottom &&
            parentRect.bottom - rect.top < Math.min(rect.height / 3, 10)) ||
          (rect.top < parentRect.top &&
            rect.bottom - parentRect.top < Math.min(rect.height / 3, 10))
        )
          return;

        onHover(item.index);
      }}
    >
      {multiselect ? (
        <Checkbox
          className={hasCursor ? styles.hasCursor : undefined}
          checked={isSelected}
          label={item.text}
          tabIndex={-1}
          disabled={!item.canSelect}
          onChange={() => void 0}
        />
      ) : (
        <span>{item.text}</span>
      )}
    </div>
  );
}

export const SelectDropDown = React.forwardRef(SelectDropDownComponent);
