import React, { Suspense, useState, useEffect } from 'react';
import { Input } from '@ui/input';
import { Link, LinkType } from '@ui/link';
import { Select } from '@ui/select';
import { Button, ButtonType } from '@ui/button';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import {
  InvalidConfirmationError,
  InvalidLoginError,
  InvalidDeviceError,
  sendConfirmation,
  ICustomerBase,
  IIntermediateSession,
  IMaintenance,
  invokeSMSSend,
  isMaintenance
} from '@api/auth/session';
import { LazyErrorBoundary } from '@lib/lazy-load-error-boundary';
import { showUnexpectedError } from '@lib/toast';
import { useSession } from '@lib/useSession';
import { MaintenanceMessage } from '@ui/planned-maintenance/maintenance-message';
import { useLoginContext } from './login.context';
import { Controller, useForm } from 'react-hook-form';
import { FormError } from '@ui/form-error';
import { checkFuncRights } from '@utils/common';
import { AuthNavigate, AuthPage, useAuthNavigation } from '../../routes/routes-config';
import styles from './login.css';

const CustomerInstanceSelect = React.lazy(
  () =>
    import(
      /* webpackChunkName: "@component-customer-instance-select" */
      '../../components/customer-instance-select-modal/customer-instance-select-modal'
    )
);

function calculateDefaultDeviceId(intermediateSession: IIntermediateSession | null) {
  if (!intermediateSession) {
    return 0;
  }

  if (intermediateSession.Devices.length === 0) {
    return 0;
  }

  return intermediateSession.Devices[0].DeviceId;
}

interface ILoginConfirmData {
  confirmationCode: string;
  selectedDeviceId: number;
  error: string;
}

export default function LoginConfirm() {
  const { t } = useTranslation();
  const { intermediateSession, processing, setProcessing } = useLoginContext();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setFocus,
    setValue,
    getValues,
    clearErrors
  } = useForm<ILoginConfirmData>({
    defaultValues: {
      confirmationCode: '',
      selectedDeviceId: calculateDefaultDeviceId(intermediateSession)
    }
  });
  const { updateSession } = useSession();
  const [maintenanceInfo, setMaintenanceInfo] = useState<IMaintenance | null>(null);
  const [isModalOpened, showModal] = useState(false);
  const navigate = useAuthNavigation();

  const [isTotp, setIsTotp] = useState((intermediateSession?.Devices.length ?? 0) > 0);

  useEffect(() => {
    setFocus('confirmationCode');
  }, []);

  if (!intermediateSession) {
    return <AuthNavigate to={AuthPage.Login} />;
  }

  function onSubmit(formData: ILoginConfirmData) {
    if (!intermediateSession) {
      return;
    }

    if (!intermediateSession.customerId) {
      showModal(true);
      return;
    }

    const matchedCustomer = intermediateSession.Customers.find(customer => {
      const val = intermediateSession.customerId.toLowerCase();

      return customer.Name.toLowerCase() === val || customer.Id.toString() === val;
    });

    if (matchedCustomer) {
      // do not handle any Promise results here
      onCustomerIdSelected(matchedCustomer);
      return;
    }
  }

  async function onCustomerIdSelected(selectedCustomer: ICustomerBase) {
    if (!intermediateSession) {
      return;
    }

    const config = {
      DeviceId: isTotp ? getValues('selectedDeviceId') : 0,
      ConfirmationCode: getValues('confirmationCode'),
      IsGoogleAuth: isTotp,
      CustomerId: selectedCustomer.Id,
      LoginName: intermediateSession.username
    };

    try {
      setProcessing(true);
      const { data } = await sendConfirmation(config);

      const platformsToShow = checkFuncRights(data ? data.FuncRights : []);

      if (!platformsToShow.showWebClient || !data?.OrgUnits.length) {
        setError('error', { type: 'validate', message: t('login:errors:noAccessToWebclient') });
        return;
      }

      updateSession(data);
    } catch (e) {
      const error = e as AxiosError;

      // TODO: check how it is hanlded in PCClient
      // Somehow backend start responde 401 on correct confirmation code
      // if (error.response && error.response.status === 401) {
      //   navigate('/');
      //   showUnexpectedError(new Error('Timeout exceeded for 2FA auth'));
      //   return;
      // }

      if (e instanceof InvalidConfirmationError) {
        setError('error', { type: 'validate', message: t('login:errors:invalidConfirmationCode') });
        return;
      }

      if (e instanceof InvalidLoginError) {
        setError('error', { type: 'validate', message: t('login:errors:incorrectCredentials') });
        return;
      }

      if (e instanceof InvalidDeviceError) {
        setError('error', { type: 'validate', message: t('login:errors:invalidDevice') });
        return;
      }

      if (error.response && isMaintenance(error.response.data)) {
        setMaintenanceInfo(error.response.data);
        return;
      }

      showUnexpectedError(error);
      setFocus('confirmationCode');
    } finally {
      setProcessing(false);
    }
  }

  return (
    <div className={styles.confirmation}>
      <h4 className={styles.message}>
        {isTotp
          ? t('login:twoFactor:enterPin')
          : t('login:twoFactor:enterCode', { phone: intermediateSession.PhoneNumber })}
      </h4>
      <form className={styles.loginForm} onSubmit={handleSubmit(onSubmit)}>
        {isTotp && intermediateSession.Devices.length > 1 ? (
          <>
            <Controller
              control={control}
              name="selectedDeviceId"
              render={({ field }) => (
                <Select
                  className={styles.deviceSelect}
                  label={t('login:twoFactor:selectDevice')}
                  ref={field.ref}
                  cyId="selectedDeviceId"
                  items={intermediateSession.Devices}
                  itemName={'DeviceName'}
                  selected={item => item.value.DeviceId === field.value}
                  hideSearch
                  disabled={processing}
                  onSelectionChanged={item => {
                    if (!item) return;
                    setValue('selectedDeviceId', item.value.DeviceId);
                  }}
                />
              )}
            />
          </>
        ) : null}
        <Input
          cy-id="confirmationCode"
          required
          autoComplete="off"
          placeholder={t('login:twoFactor:confirmationCode')}
          {...register('confirmationCode', {
            required: true,
            onChange: () => {
              setMaintenanceInfo(null);
            }
          })}
        />

        {errors.error && errors.error.message && <FormError text={errors.error.message} />}
        <MaintenanceMessage maintenance={maintenanceInfo} className={styles.loginMaintenance} />
        <div style={{ marginTop: '15px' }}>
          <Button
            cy-id="submitConfirmForm"
            disabled={processing}
            value={t('common:confirm')}
            type={ButtonType.submit}
            style={{ marginRight: '15px' }}
            onClick={() => clearErrors('error')}
          />
          <Button
            disabled={processing}
            onClick={() => navigate(AuthPage.Login)}
            value={t('common:back')}
            type={ButtonType.submit}
          />
        </div>
      </form>

      {isTotp && (
        <div style={{ marginTop: '15px' }}>
          <Link
            text={t('login:twoFactor:sendSms', { phone: intermediateSession.PhoneNumber })}
            type={LinkType.link}
            onClick={() =>
              invokeSMSSend({
                LoginName: intermediateSession.username,
                CustomerId: intermediateSession.customerId
              })
                .then(() => {
                  setFocus('confirmationCode');
                  setIsTotp(false);
                })
                .catch(e => showUnexpectedError(e))
            }
          />
        </div>
      )}
      <LazyErrorBoundary
        onError={() => showUnexpectedError(new Error(`Can't load CustomerInstanceSelect module`))}
      >
        <Suspense fallback={null}>
          <CustomerInstanceSelect
            onRequestClose={() => {
              showModal(false);
            }}
            isBusy={processing}
            isOpen={isModalOpened}
            customers={intermediateSession.Customers}
            onCustomerSelect={customer => {
              showModal(false);
              onCustomerIdSelected(customer);
            }}
          />
        </Suspense>
      </LazyErrorBoundary>
    </div>
  );
}
