import React, { useEffect, useLayoutEffect, useRef, useState, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';
import styles from './menu.module.css';

export enum MenuPopup {
  Horizontal,
  Vertical
}

export type MenuItem = IMenuItem & IMenuItemJSX;

interface IMenuItemBase {
  separator?: boolean;
  disabled?: boolean;
  subItems?: MenuItem[];
  onClick?(): void;
}
interface IMenuItem extends IMenuItemBase {
  text?: string;
  icon?: ReactNode;
  shortcut?: string;
  checked?: boolean;

  cyId?: string;
}

interface IMenuItemJSX extends IMenuItemBase {
  element?: JSX.Element;
}

export interface IMenuProps {
  items: MenuItem[];
  position?: { x: number; y: number };
  isSubMenu?: boolean;
  menuPopup?: MenuPopup;
  onDismiss?(selectedItem?: MenuItem): void;

  iconClassName?: string;
  rootClassName?: string;
  menuClassName?: string;
  itemClassName?: string;

  cyId?: string;
}

export function Menu(props: IMenuProps) {
  const {
    items,
    position,
    isSubMenu,
    onDismiss,
    rootClassName,
    menuClassName,
    itemClassName,
    cyId
  } = props;
  const [focusedItemIndex, setFocusedItemIndex] = useState(-1);
  const [submenuExpanded, setSubmenuExpanded] = useState(false);
  const [dismissed, setDismissed] = useState<boolean | IMenuItem>(false);
  const menuRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (submenuExpanded) {
        return;
      }

      switch (e.code) {
        case 'Escape':
          {
            e.preventDefault();
            e.stopPropagation();
            setDismissed(true);
          }
          break;
        case 'Enter':
          {
            e.preventDefault();
            e.stopPropagation();
            if (focusedItemIndex >= 0 && focusedItemIndex < items.length) {
              const item = items[focusedItemIndex];
              if (item.subItems?.length) {
                setSubmenuExpanded(true);
              } else if (!item.separator) {
                setDismissed(item);
                item.onClick?.();
              }
            }
          }
          break;
        case 'ArrowUp':
          {
            e.preventDefault();
            e.stopPropagation();
            let nextFocusedItem = focusedItemIndex - 1;
            if (nextFocusedItem < 0 || nextFocusedItem >= items.length)
              nextFocusedItem = items.length - 1;
            for (let i = 0; i < items.length; i++) {
              let index = nextFocusedItem - i;
              if (index < 0) index += items.length;
              if (!items[index].separator) {
                setFocusedItemIndex(index);
                break;
              }
            }
          }
          break;
        case 'ArrowDown':
          {
            e.preventDefault();
            e.stopPropagation();
            let nextFocusedItem = focusedItemIndex + 1;
            if (nextFocusedItem < 0 || nextFocusedItem >= items.length) nextFocusedItem = 0;
            for (let i = 0; i < items.length; i++) {
              let index = nextFocusedItem + i;
              if (index >= items.length) index -= items.length;
              if (!items[index].separator) {
                setFocusedItemIndex(index);
                break;
              }
            }
          }
          break;
        case 'ArrowLeft':
          {
            e.preventDefault();
            e.stopPropagation();
            if (isSubMenu) {
              setDismissed(true);
            }
          }
          break;
        case 'ArrowRight':
          {
            e.preventDefault();
            e.stopPropagation();
            if (focusedItemIndex > 0 && focusedItemIndex < items.length) {
              if (items[focusedItemIndex].subItems?.length) {
                setSubmenuExpanded(true);
              }
            } else {
              setFocusedItemIndex(0);
            }
          }
          break;
      }
    };

    document.addEventListener('keydown', onKeyDown, true);
    return () => {
      document.removeEventListener('keydown', onKeyDown, true);
    };
  }, [focusedItemIndex, items, submenuExpanded]);

  useEffect(() => {
    if (isSubMenu) return;
    if (dismissed) return;

    const subscribeToMenuDismissEvents = () => {
      window.addEventListener('pointerdown', handleOutsideEvent, true);
      window.addEventListener('wheel', handleOutsideEvent, true);
      window.addEventListener('scroll', handleOutsideEvent, true);
      window.addEventListener('resize', dismissMenu, true);
    };

    const unsubscribeFromMenuDismissEvents = () => {
      window.removeEventListener('pointerdown', handleOutsideEvent, true);
      window.removeEventListener('wheel', handleOutsideEvent, true);
      window.removeEventListener('scroll', handleOutsideEvent, true);
      window.removeEventListener('resize', dismissMenu, true);
    };

    const handleOutsideEvent = (e: Event) => {
      if (!menuRef.current) return;
      const insideClick =
        e.target &&
        e.target instanceof Node &&
        (menuRef.current === e.target || menuRef.current.contains(e.target));

      if (!insideClick) {
        if (menuRef.current.parentElement?.contains(e.target as Node | null)) {
          e.preventDefault();
        }
        dismissMenu();
      }
    };

    const dismissMenu = () => {
      unsubscribeFromMenuDismissEvents();
      setDismissed(true);
    };

    subscribeToMenuDismissEvents();
    return () => {
      unsubscribeFromMenuDismissEvents();
    };
  }, [menuRef, isSubMenu, dismissed]);

  useLayoutEffect(() => {
    // This effect sets correct left/top position
    if (!menuRef.current) return;

    const menuWidth = menuRef.current.offsetWidth;
    const menuHeight = menuRef.current.offsetHeight;

    const offset = isSubMenu ? 3 : 0;

    const menuRect = menuRef.current.getBoundingClientRect();
    const parentRect = menuRef.current?.parentElement?.getBoundingClientRect();
    const menuPopup = props.menuPopup ?? MenuPopup.Horizontal;

    let left = 0;
    let top = 0;
    if (menuPopup === MenuPopup.Horizontal) {
      // Popup to the left/right
      left = position?.x ?? (parentRect ? parentRect.right - offset : 0);
      if (left + menuWidth > window.innerWidth) {
        left = (position?.x ?? (parentRect ? parentRect.left + offset : 0)) - menuWidth;
      }

      top = position?.y ?? (parentRect ? parentRect.top - offset : 0);
      if (top + menuHeight > window.innerHeight) {
        top = window.innerHeight - menuHeight;
      }
    } else {
      // Popup to the top/bottom
      top = position?.y ?? (parentRect ? parentRect.bottom - offset : 0);
      if (top + menuHeight > window.innerHeight) {
        top = (position?.y ?? (parentRect ? parentRect.top + offset : 0)) - menuHeight;
      }

      left = position?.x ?? (parentRect ? parentRect.left - offset : 0);
      if (left + menuWidth > window.innerWidth) {
        left = window.innerWidth - menuWidth;
      }
    }

    // Adjust position if it crossed window's left/top border
    if (left < 0) {
      left = 0;
    }

    if (top < 0) {
      top = 0;
    }

    menuRef.current.style.transform = `translate(${left - menuRect.left}px, ${
      top - menuRect.top
    }px)`;

    menuRef.current.animate([{ opacity: 0 }, { opacity: 1 }], 200);
  }, []);

  useEffect(() => {
    if (!dismissed) return;

    const item = typeof dismissed === 'boolean' ? undefined : dismissed;
    onDismiss?.(item);
  }, [dismissed, onDismiss]);

  if (dismissed) {
    return null;
  }

  return (
    <nav
      className={cx(styles.menuRoot, rootClassName ?? '')}
      ref={menuRef}
      cy-id={cyId}
      onWheel={e => {
        if (isSubMenu) return;
        e.stopPropagation();
      }}
      onPointerDown={e => e.stopPropagation()}
    >
      <ul
        className={cx(styles.menu, 'scrollable', menuClassName ?? '')}
        cy-id={'menu-items'}
        onPointerDown={e => e.stopPropagation()}
      >
        {items.map((item, index) => {
          let menuNode = item.element;

          if (!menuNode && !item.separator) {
            const text =
              !item.separator && item.text ? (
                <span className={styles.menuText}>{item.text}</span>
              ) : null;

            const shortcutText =
              !item.separator && item.shortcut ? (
                <span className={styles.shortcut}>{item.shortcut}</span>
              ) : null;

            const icon = (
              <span className={cx(styles.icon, props.iconClassName ?? '')}>
                {item.icon ?? (item.checked ? <FontAwesomeIcon icon={faCheck} /> : null)}
              </span>
            );

            menuNode = (
              <>
                {icon}
                {text}
                {shortcutText}
              </>
            );
          }

          return (
            <li
              className={cx(
                styles.item,
                {
                  [styles.separator]: item.separator,
                  [styles.disabled]: item.disabled,
                  [styles.focused]: index === focusedItemIndex
                },
                itemClassName ?? ''
              )}
              tabIndex={0}
              key={index}
              cy-id={item.cyId}
              onPointerDown={e => {
                e.stopPropagation();
              }}
              onClick={e => {
                e.stopPropagation();
                if (!item.disabled) {
                  setFocusedItemIndex(index);
                  setSubmenuExpanded(!!item.subItems?.length);
                  if (!item.subItems?.length && !item.separator) {
                    item.onClick?.();
                    setDismissed(item);
                  }
                }
              }}
              onMouseEnter={() => {
                setFocusedItemIndex(index);
                setSubmenuExpanded(!!item.subItems?.length);
              }}
            >
              {menuNode}

              {item.subItems?.length && (
                <FontAwesomeIcon icon={faAngleRight} className={styles.submenuIcon} />
              )}

              {index === focusedItemIndex && item.subItems?.length && submenuExpanded ? (
                <Menu
                  items={item.subItems}
                  isSubMenu={true}
                  rootClassName={rootClassName}
                  menuClassName={menuClassName}
                  itemClassName={itemClassName}
                  onDismiss={selectedItem => {
                    if (selectedItem) {
                      setDismissed(selectedItem);
                    } else {
                      setSubmenuExpanded(false);
                    }
                  }}
                />
              ) : null}
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
