import { Component } from 'react';

interface ILazyErrorBoundaryState {
  error: Error | null;
  errorInfo: any | null;
}

interface ILazyErrorBoundaryProps {
  children: React.ReactNode;
  onError(error: Error, errorInfo: any): void;
}

export class LazyErrorBoundary extends Component<ILazyErrorBoundaryProps, ILazyErrorBoundaryState> {
  state: ILazyErrorBoundaryState = { error: null, errorInfo: null };

  componentDidCatch(error: Error, errorInfo: any) {
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.error && this.state.errorInfo) {
      // render fallback UI
      // FIXME: add lazy load error UI
      this.props.onError(this.state.error, this.state.errorInfo);
      return null;
    } else {
      // when there's not an error, render children untouched
      return this.props.children;
    }
  }
}
